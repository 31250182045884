<template>
    <div class="surface-card p-6 border-1 surface-border">
        <data-table-view
            endpoint="user-reals"
            :headline="$t('Users')"
        >
            <template slot="actions">
                <router-link to="/users/create">
                    <Button
                        :label="$t('Create new user')"
                        class="p-button-primary p-button-sm"
                    />
                </router-link>
            </template>
            <Column
                field="username"
                :header="$t('Username')"
                sortable
            >
                <template #body="slotProps">
                    {{ slotProps.data.displayName }}
                    <template v-if="!slotProps.data.active">
                        <small>
                            (inaktiv)
                        </small>
                    </template>
                </template>
            </Column>
            <Column
                field="email"
                :header="$t('E-Mail')"
                filterMatchMode="contain"
                ref="email"
                :sortable="true"
            />
            <Column
                :header="$t('Company')"
                field="company.name"
            />

            <Column
                field="lastLogin"
                :header="$t('Last login')"
                sortable
            >
                <template #body="slotProps">
                    {{ slotProps.data.lastLogin|formatDate('LLL') }}
                </template>
            </Column>

            <Column
                :header="$t('Active')"
            >
                <template #body="slotProps">
                    <i class="pi"
                       :class="{
                            'true-icon pi-check-circle': slotProps.data.active,
                        }"
                    ></i>
                </template>
            </Column>
            <Column header="" :styles="{'text-align': 'right'}">
                <template #body="slotProps">
                    <router-link
                        :to="'/users/' + slotProps.data.id"
                        v-if="slotProps.data.userCanUpdate ||true"
                    >
                        <i class="pi pi-pencil"></i>
                    </router-link>
                </template>
            </Column>
        </data-table-view>
    </div>
</template>

<script>
    import Button from 'primevue/button';
    import Column from 'primevue/column';
    import DataTableView from "@/components/DataTableView";
    import InputText from "primevue/inputtext/InputText";
    import TriStateCheckbox from "primevue/tristatecheckbox";

    export default {
        components: {
            InputText,
            Button,
            DataTableView,
            Column,
            TriStateCheckbox
        },
        methods: {}
    }
</script>
